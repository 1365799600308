import React from 'react';

const Footer = () => (
  <footer className="w-full bg-[#0E0E11] text-[#848484] pt-12 pb-8 text-[13px] leading-relaxed">
    <div className="max-w-6xl mx-auto px-4 relative">
      <div className="absolute left-0 right-[-18%] top-0 h-[0.4px] bg-[#848484] opacity-50"></div>
      <div className="pt-10">
        <p className="mb-4">Copyright © Basicfive Inc. All rights reserved.</p>
        <p className="mb-[0.1rem]">
          (주) 베이직파이브 대표이사 전휘찬 <span className="mx-2 text-[#848484] opacity-50">|</span> 주소 : 서울특별시 마포구 마포대로 122 18층 <span className="mx-2 text-[#848484] opacity-50">|</span> 사업자등록번호 : 787-86-03205
        </p>
        <p className="mb-4">
          통신판매업신고번호 : 제 2024-서울마포-1595 <span className="mx-2 text-[#848484] opacity-50">|</span> 이메일문의 : gnlcks82@gmail.com
        </p>
        <div className="flex items-center">
          <a href="https://marshy-gruyere-e77.notion.site/073660cf77944d3495d1d8c5ff2e2bd0" target="_blank" rel="noreferrer" className="underline hover:text-white transition-colors duration-200">
            서비스 이용약관
          </a>
          <span className="mx-2 text-[#848484] opacity-50">|</span>
          <a href="https://www.google.com/" target="_blank" rel="noreferrer" className="underline hover:text-white transition-colors duration-200">
            개인정보 처리방침
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;