import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

// 스크롤 휠 애니메이션 컴포넌트
const ScrollWheelAnimation = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="relative w-16 h-16">
      <div className="absolute border-8 border-t-transparent border-solid border-white rounded-full w-full h-full spin"></div>
    </div>
  </div>
);

// Shimmer 효과 컴포넌트
const ShimmerEffect = () => (
  <div className="relative overflow-hidden rounded-lg h-full w-full bg-gray-800">
    <div className="absolute top-0 left-0 w-full h-full animate-shimmer-move">
      <div className="absolute top-0 -left-full w-[200%] h-full bg-gradient-to-r from-transparent via-gray-700 to-transparent transform -skew-x-12"></div>
    </div>
  </div>
);

const ImageGenerationLoading = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedHair, selectedBackground } = location.state;
  const [loading, setLoading] = useState(true);
  const [showImages, setShowImages] = useState(false);

  // 더미 이미지 URL 배열 (실제 URL로 교체해야 함)
  const dummyImages = [
    'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/GENERATED_IMAGE/%E1%84%92%E1%85%B5%E1%84%91%E1%85%B5%E1%84%91%E1%85%A5%E1%86%B7/2_%E1%84%86%E1%85%B5%E1%84%82%E1%85%B5%E1%84%86%E1%85%A5%E1%86%AF_%E1%84%92%E1%85%AA%E1%84%8B%E1%85%B5%E1%84%90%E1%85%B3/model1.png',
    'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/GENERATED_IMAGE/%E1%84%92%E1%85%B5%E1%84%91%E1%85%B5%E1%84%91%E1%85%A5%E1%86%B7/2_%E1%84%86%E1%85%B5%E1%84%82%E1%85%B5%E1%84%86%E1%85%A5%E1%86%AF_%E1%84%92%E1%85%AA%E1%84%8B%E1%85%B5%E1%84%90%E1%85%B3/model11.png',
    'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/GENERATED_IMAGE/%E1%84%92%E1%85%B5%E1%84%91%E1%85%B5%E1%84%91%E1%85%A5%E1%86%B7/2_%E1%84%86%E1%85%B5%E1%84%82%E1%85%B5%E1%84%86%E1%85%A5%E1%86%AF_%E1%84%92%E1%85%AA%E1%84%8B%E1%85%B5%E1%84%90%E1%85%B3/model12.png',
    'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/GENERATED_IMAGE/%E1%84%92%E1%85%B5%E1%84%91%E1%85%B5%E1%84%91%E1%85%A5%E1%86%B7/2_%E1%84%86%E1%85%B5%E1%84%82%E1%85%B5%E1%84%86%E1%85%A5%E1%86%AF_%E1%84%92%E1%85%AA%E1%84%8B%E1%85%B5%E1%84%90%E1%85%B3/model13.png',
    'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/GENERATED_IMAGE/%E1%84%92%E1%85%B5%E1%84%91%E1%85%B5%E1%84%91%E1%85%A5%E1%86%B7/2_%E1%84%86%E1%85%B5%E1%84%82%E1%85%B5%E1%84%86%E1%85%A5%E1%86%AF_%E1%84%92%E1%85%AA%E1%84%8B%E1%85%B5%E1%84%90%E1%85%B3/model14.png',
    'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/GENERATED_IMAGE/%E1%84%92%E1%85%B5%E1%84%91%E1%85%B5%E1%84%91%E1%85%A5%E1%86%B7/2_%E1%84%86%E1%85%B5%E1%84%82%E1%85%B5%E1%84%86%E1%85%A5%E1%86%AF_%E1%84%92%E1%85%AA%E1%84%8B%E1%85%B5%E1%84%90%E1%85%B3/model15.png',
    'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/GENERATED_IMAGE/%E1%84%92%E1%85%B5%E1%84%91%E1%85%B5%E1%84%91%E1%85%A5%E1%86%B7/2_%E1%84%86%E1%85%B5%E1%84%82%E1%85%B5%E1%84%86%E1%85%A5%E1%86%AF_%E1%84%92%E1%85%AA%E1%84%8B%E1%85%B5%E1%84%90%E1%85%B3/model16.png',
    'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/GENERATED_IMAGE/%E1%84%92%E1%85%B5%E1%84%91%E1%85%B5%E1%84%91%E1%85%A5%E1%86%B7/2_%E1%84%86%E1%85%B5%E1%84%82%E1%85%B5%E1%84%86%E1%85%A5%E1%86%AF_%E1%84%92%E1%85%AA%E1%84%8B%E1%85%B5%E1%84%90%E1%85%B3/model17.png',
    'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/GENERATED_IMAGE/%E1%84%92%E1%85%B5%E1%84%91%E1%85%B5%E1%84%91%E1%85%A5%E1%86%B7/2_%E1%84%86%E1%85%B5%E1%84%82%E1%85%B5%E1%84%86%E1%85%A5%E1%86%AF_%E1%84%92%E1%85%AA%E1%84%8B%E1%85%B5%E1%84%90%E1%85%B3/model18.png',
    'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/GENERATED_IMAGE/%E1%84%92%E1%85%B5%E1%84%91%E1%85%B5%E1%84%91%E1%85%A5%E1%86%B7/2_%E1%84%86%E1%85%B5%E1%84%82%E1%85%B5%E1%84%86%E1%85%A5%E1%86%AF_%E1%84%92%E1%85%AA%E1%84%8B%E1%85%B5%E1%84%90%E1%85%B3/model19.png',
  ];

  useEffect(() => {
    // 30초 후에 로딩 상태를 false로 변경
    const timer = setTimeout(() => {
      setLoading(false);
      setShowImages(true);
    }, 30000);

    return () => clearTimeout(timer);
  }, []);

  const handleDownloadZip = async () => {
    const zip = new JSZip();
    
    // 이미지를 ZIP 파일에 추가
    for (let i = 0; i < dummyImages.length; i++) {
      const response = await fetch(dummyImages[i]);
      const blob = await response.blob();
      zip.file(`image${i + 1}.jpg`, blob);
    }
    
    // ZIP 파일 생성 및 다운로드
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "generated_images.zip");
  };

  const handleBetaService = () => {
    navigate('/beta');
  };

  return (
    <div className="flex bg-[#0E0E11] text-white min-h-screen">
      <div className="w-64 flex-shrink-0"></div>
      <div className="flex-grow px-8 py-8 overflow-hidden">
        <div className="text-mid text-[#BDBDBD] mb-4 text-left">
          <p>*화면을 나가면 생성된 결과를 다시 확인할 수 없습니다.</p>
          <p>*예상 대기시간은 30초 입니다.</p>
        </div>
        {loading ? (
          <div className="grid grid-cols-5 gap-2 w-full">
            {[...Array(10)].map((_, index) => (
              <div key={index} className="aspect-square rounded-lg overflow-hidden">
                <ShimmerEffect />
              </div>
            ))}
          </div>
        ) : showImages ? (
          <>
            <div className="grid grid-cols-5 gap-2 w-full mb-8">
              {dummyImages.map((imageUrl, index) => (
                <div key={index} className="aspect-square rounded-lg overflow-hidden">
                  <img src={imageUrl} alt={`Generated ${index + 1}`} className="w-full h-full object-cover" />
                </div>
              ))}
            </div>
            <div className="flex justify-start space-x-4 mt-4">
              <button
                onClick={handleDownloadZip}
                className="px-4 py-2 bg-white text-black rounded"
              >
                전체 다운하기 .zip
              </button>
              <button
                onClick={handleBetaService}
                className="px-4 py-2 bg-transparent text-white border border-white rounded"
              >
                베타 서비스 사용하기
              </button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ImageGenerationLoading;
