import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ImageCard = ({ image, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      className="relative cursor-pointer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      <img 
        src={image.image_url} 
        alt={`${image.hairstyle_name} - ${image.background_name}`}
        className="w-full h-full object-cover rounded-lg"
      />
      {isHovered && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-end justify-start p-4 rounded-lg">
          <div className="text-white text-left">
            <p>#{image.hairstyle_name}</p>
            <p>#{image.background_name}</p>
          </div>
        </div>
      )}
    </div>
  );
};

const Modal = ({ image, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={onClose}>
      <div className="max-w-3xl max-h-3xl" onClick={e => e.stopPropagation()}>
        <img 
          src={image.image_url} 
          alt={`${image.hairstyle_name} - ${image.background_name}`}
          className="w-full h-full object-contain"
        />
      </div>
    </div>
  );
};

const GeneratedImages = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get('https://api.basicfive.co.kr/api/v1/generated-images/');
        setImages(response.data.generated_image_list);
      } catch (err) {
        console.error('Error fetching images:', err);
        setError('이미지를 불러오는 데 실패했습니다.');
      } finally {
        setLoading(false);
      }
    };
    fetchImages();
  }, []);
  
  if (loading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  if (error) {
    return <div className="text-center mt-8 text-red-500">{error}</div>;
  }

  return (
    <div className="flex">
      <div className="w-64 flex-shrink-0"></div> 
      <div className="flex-grow px-8 py-8 overflow-hidden">
        <h1 className="text-3xl font-bold mb-6">생성된 이미지</h1>
        <div className="grid grid-cols-5 gap-2 w-full">
          {images.map((image) => (
            <ImageCard 
              key={image.id} 
              image={image} 
              onClick={() => setSelectedImage(image)}
            />
          ))}
        </div>
        {selectedImage && (
          <Modal 
            image={selectedImage} 
            onClose={() => setSelectedImage(null)}
          />
        )}
      </div>
    </div>
  );
};

export default GeneratedImages;