import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const NavigationBar = () => {
  const location = useLocation();
  const navItems = [
    { name: '서비스 소개', path: '/' },
    { name: '생성된 이미지', path: '/generated-images' },
    { name: '데모 체험하기', path: '/demo' },
    { name: '베타버전 사용하기', path: '/beta' },
  ];

  const isActive = (path) => {
    if (location.pathname === '/' && path === '/service') {
      return true;
    }
    return location.pathname === path;
  };

  return (
    <nav className="fixed left-0 top-0 h-full w-64 bg-[#0E0E11] p-6 flex flex-col">
      <div className="mb-10 pl-4 pt-6">
        <h1 className="text-[#EAEAEA] text-3xl font-bold">에헤모</h1>
        <p className="text-[#878787] text-sm">사진퀄리티 AI 헤어 모델</p>
      </div>
      
      <ul className="flex-grow py-6">
        {navItems.map((item) => (
          <li key={item.name} className="mb-[0.1rem]">
            <Link
              to={item.path}
              className={`block py-3 px-8 rounded-xl font-bold ${
                isActive(item.path)
                  ? 'bg-[#1D1D1D] text-white'
                  : 'text-[#878787]'
              }`}
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
      
      <div className="mt-auto pl-4 pb-4">
        <a href="https://marshy-gruyere-e77.notion.site/073660cf77944d3495d1d8c5ff2e2bd0" target="_blank" rel="noreferrer" className="block text-[#D8D8D8] underline mb-2 text-sm">서비스 이용약관</a>
        <a href="https://www.google.com/" target="_blank" rel="noreferrer" className="block text-[#D8D8D8] underline mb-4 text-sm">개인정보 처리방침</a>
        <p className="text-[#989898] text-xs">
          Copyright © Basicfive Inc.<br />
          All rights reserved.
        </p>
      </div>
    </nav>
  );  
};

export default NavigationBar;