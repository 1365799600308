import React, { useState } from 'react';
import { ArrowRight } from 'lucide-react';

import Popup from '../popup/Popup'; // Popup 컴포넌트 import



const SecondSection = () => {
  const image1 = 'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/MAIN_PAGE_SECOND/main.png';
  const image2 = 'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/MAIN_PAGE_SECOND/behind.png';

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  const handleKakaoChannelClick = () => {
    // 카카오톡 채널 URL을 여기에 넣으세요
    window.open('http://pf.kakao.com/_JxehAn/chat', '_blank');
  };

  return (
    <div className="flex items-center justify-center bg-[#0E0E11] text-white py-16 px-8">
      <div className="flex items-center justify-between max-w-6xl w-full ml-20"> {/* ml-12 added here */}
        <div className="flex justify-end items-center w-1/2 pr-4">
          <div className="relative w-3/5">  {/* 이미지 컨테이너 */}
            {/* 두 번째 이미지 (배경) */}
            <div className="absolute top-1/2 left-0 w-[90%] -translate-x-[46%] -translate-y-[46%] z-0">
              <img 
                src={image2}
                alt="배경 이미지" 
                className="w-full h-auto"
              />
            </div>
            
            {/* 첫 번째 이미지 (전경) */}
            <div className="relative z-10">
              <img 
                src={image1}
                alt="전경 이미지" 
                className="w-full h-auto"
              />
            </div>
          </div>
        </div>
        {/* Text Section */}
        <div className="w-1/2 p-10 text-left">
          <h2 className="text-5xl font-bold mb-8 leading-tight">
            원장님들께서<br />
            에헤모를 통해<br />
            <span className="bg-gradient-to-tr from-[#DA9EFF] to-[#0B41FF] text-transparent bg-clip-text">
              매달 10만원씩
            </span><br />
            세이브하고 계십니다
          </h2>
          <p className="text-[#999999] mb-4">
            매달 4~5명의 헤어모델 구인,<br />
            건 당 재료비(염색) 및 자재비 2~3만원,<br />
            시술하는 본인의 시간까지.
          </p>
          <p className="text-[#999999] mb-8">
            네이버 시술 / 인스타 게시물 등<br />
            반복적인 헤어모델 작업비,<br />
            마치 휴대폰으로 촬영한듯한 모델 퀄리티로<br />
            에헤모가 세이브해드리고 있습니다.
            <p className="text-xs text-[#999999] mt-5 w-3/5 text-left">
              *초록색 테두리 표기는 에헤모로 생성된 이미지 표기임
            </p>
          </p>
          <button onClick={openPopup} className="bg-white text-black px-6 py-3 rounded-full flex items-center inline-flex">
            에헤모 서비스 이용료 <ArrowRight className="ml-2 h-4 w-4" />
          </button>
          <Popup
            isOpen={isPopupOpen}
            onClose={closePopup}
            onKakaoChannelClick={handleKakaoChannelClick}
          />
        </div>
      </div>
    </div>
  );
};

export default SecondSection;