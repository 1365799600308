import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const BetaContent = () => {
  const kakao_image = 'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/BETA/beta.png';
  
  return (
    <div className="bg-[#0E0E11] text-white min-h-screen flex items-center">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          {/* Text Section */}
          <div className="w-full lg:w-1/2 mb-8 lg:mb-0 pl-40"> {/* 왼쪽 패딩 추가 */}
            <h2 className="text-4xl font-bold mb-5 leading-tight">
              베타 서비스 사용하기
            </h2>
            <p className="text-[#999999] mb-5 leading-relaxed">
              에헤모는 현재 카카오톡 채널을 통해 베타 서비스를 제공하고 있습니다.<br />
              카카오톡 채널 추가 후, 챗봇을 통해 이미지 생성을 요청하면<br />
              10장의 이미지가 생성되어 반환됩니다.
            </p>
            <Link 
              to="http://pf.kakao.com/_JxehAn/chat"
              target = "_blank"
              rel="noreferrer"
              className="bg-white text-black font-bold px-6 py-3 rounded-lg flex items-center inline-flex"
              style={{ width: 'fit-content' }}
            >
              카카오톡 채널 이동 <ArrowRight className="ml-2 h-4 w-4" />
            </Link>
          </div>
          
          {/* Image Section */}
          <div className="w-full lg:w-1/2 flex justify-center lg:justify-end">
            <img 
              src={kakao_image}
              alt="배경 이미지" 
              className="w-auto h-[700px] rounded-lg object-contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetaContent;