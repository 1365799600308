import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import MainContent from './components/main-page/MainContent';
import BetaContent from './components/beta/BetaContent';
import GeneratedImages from './components/generated-images/GeneratedImages';
import StyleSelectionPage from './components/demo/StyleSelectionPage'
import ImageGenerationLoading from './components/demo/GenerationWaitingPage';
import './styles.css'

function App() {
  return (
    <Router>
      <div className="flex flex-col bg-default min-h-screen">
        <NavigationBar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<MainContent />} />
            <Route path="/generated-images" element={<GeneratedImages />} />
            <Route path="/demo" element={<StyleSelectionPage />} />
            <Route path="/beta" element={<BetaContent />} />
            <Route path="/demo/generation" element={<ImageGenerationLoading />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;