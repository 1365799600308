import React from 'react';

const Popup = ({ isOpen, onClose, onKakaoChannelClick }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-[#EDEDED] rounded-lg p-4 w-10/12 max-w-[240px] aspect-[4/5] flex flex-col">
        <div className="flex-grow flex flex-col justify-center">
          <h2 className="text-xl font-bold mb-4 text-center text-[#0E0E11]">에헤모 서비스 이용료</h2>
          <p className="text-[#0E0E11] mb-2 text-center text-sm leading-normal mx-auto max-w-[80%]">
            서비스 가격 정보는<br/>
            카카오톡 채널톡에서<br/>
            확인하실 수 있습니다.<br/>
          </p>
          <p className="text-[#0E0E11] mb-4 text-center text-sm leading-normal mx-auto max-w-[80%]">
            하단의 버튼을 통해<br/>
            채널로 이동해주세요.
          </p>
        </div>
        <div className="flex flex-col space-y-2">
          <button
            onClick={onKakaoChannelClick}
            className="bg-[#0E0E11] hover:bg-gray-900 text-white font-bold py-2 px-3 rounded text-sm"
          >
            카카오톡 채널 이동
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 hover:bg-gray-400 text-[#0E0E11] font-bold py-2 px-3 rounded text-sm"
          >
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;