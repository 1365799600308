import React, { useState, useRef, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const hairStyles = [
  {
    id: 1,
    image: 'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/MAIN_PAGE_FOURTH/hairstyle/1_mid_hush.jpg',
    title: '중단발 허쉬컷',
    description: '중단발 길이에서 층이 길게 커트돼있는 차분한 느낌의 허쉬컷'
  },
  {
    id: 2,
    image: 'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/MAIN_PAGE_FOURTH/hairstyle/2_hippy.jpg',
    title: '히피펌',
    description: '부스스한 느낌에 자유분방하고 사랑스러운 느낌을 낼 수 있는 스타일'
  },
  {
    id: 3,
    image: 'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/MAIN_PAGE_FOURTH/hairstyle/3_tassle.jpg',
    title: '태슬컷',
    description: '층 없이 깔끔한 라인으로 간결하고 세련된 연출이 가능한 단발 커트'
  },
  {
    id: 4,
    image: 'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/MAIN_PAGE_FOURTH/hairstyle/4_more_to_come.jpg',
    title: '더 많은 스타일 추가 예정',
    description: '매주 새로운 헤어스타일이 추가될 예정입니다'
  }
];

const backgroundStyles = [
  {
    id: 1,
    image: 'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/MAIN_PAGE_FOURTH/background/1_pure_white.jpg',
    title: '퓨어 화이트',
    description: '아무런 질감이나 패턴 없이 완벽하게 깨끗한 흰색 배경.'
  },
  {
    id: 2,
    image: 'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/MAIN_PAGE_FOURTH/background/2_minimal_white.jpg',
    title: '미니멀 화이트',
    description: '페인트나 시멘트의 섬세한 디테일이 살짝 드러나 깊이감을 더해주는 화이트.'
  },
  {
    id: 3,
    image: 'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/MAIN_PAGE_FOURTH/background/3_concrete.jpg',
    title: '노출 콘크리트',
    description: '산업적이고 현대적인 느낌을 주는 거친 질감의 회색 배경.'
  },
  {
    id: 4,
    image: 'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/MAIN_PAGE_FOURTH/background/4_charcoal.jpg',
    title: '차콜 슬레이트',
    description: '고급스럽고 세련된 분위기를 연출하는 깊이 있는 어두운 배경.'
  },
  {
    id: 5,
    image: 'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/MAIN_PAGE_FOURTH/background/5_red_brick.jpg',
    title: '레드 브릭',
    description: '전통적인 붉은 벽돌 벽. 따뜻한 분위기를 자아내는 텍스처가 풍부한 배경.'
  },
  {
    id: 6,
    image: 'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/MAIN_PAGE_FOURTH/background/6_navy_grey.jpg',
    title: '네이비 그레이',
    description: '세련되고 차분한 분위기를 연출하는 깊이 있는 색상의 배경.'
  },
  {
    id: 7,
    image: 'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/MAIN_PAGE_FOURTH/background/7_more_to_come.jpg',
    title: '더 많은 스타일 추가 예정',
    description: '매주 새로운 배경이 추가될 예정입니다'
  },
];

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);
  const imageWidth = 400; // 이미지 너비 (픽셀)
  const imageGap = 10; // 이미지 간 간격 (픽셀)

  const nextSlide = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(prevIndex => prevIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prevIndex => prevIndex - 1);
    }
  };

  useEffect(() => {
    if (carouselRef.current) {
      const initialOffset = imageWidth / 2 + imageGap / 2;
      const offset = currentIndex * (imageWidth + imageGap);
      carouselRef.current.style.transform = `translateX(calc(-${initialOffset}px - ${offset}px))`;
    }
  }, [currentIndex]);

  return (
    <div className="flex flex-col items-center justify-between w-full mb-16">
      <div className="relative w-full overflow-hidden">
        <div 
          ref={carouselRef}
          className="flex transition-all duration-300 ease-in-out"
          style={{
            marginLeft: '50%',
            transform: `translateX(calc(-${imageWidth / 2 + imageGap / 2}px))`,
          }}
        >
          {images.map((image, index) => (
            <div 
              key={image.id} 
              className="flex-shrink-0" 
              style={{ 
                width: `${imageWidth}px`, 
                marginRight: `${imageGap}px`
              }}
            >
              <img
                src={image.image}
                alt={image.title}
                className="w-full h-auto object-cover"
                style={{ aspectRatio: '1 / 1' }}
              />
            </div>
          ))}
        </div>
        <GradientOverlay />
        <CarouselButton direction="left" onClick={prevSlide} disabled={currentIndex === 0} />
        <CarouselButton direction="right" onClick={nextSlide} disabled={currentIndex === images.length - 1} />
      </div>
      <div className="flex justify-center mt-4 mb-4">
        {images.map((_, index) => (
          <div
            key={index}
            className={`w-[8px] h-[8px] rounded-full mx-[0.2rem] ${
              index === currentIndex ? 'bg-white' : 'bg-[#666666]'
            }`}
          />
        ))}
      </div>
      <div className="text-center">
        <h3 className="text-2xl font-semibold text-white mb-[0.1rem]">
          {images[currentIndex].title}
        </h3>
        <p className="text-[#999999]">
          {images[currentIndex].description}
        </p>
      </div>
    </div>
  );
};

const GradientOverlay = () => (
  <>
    <div className="absolute top-0 left-0 h-full w-[30%]" style={{
      background: 'linear-gradient(to right, #0E0E11 0%, rgba(14,14,17,0.7) 70%, rgba(14,14,17,0) 100%)'
    }}></div>
    <div className="absolute top-0 right-0 h-full w-[30%]" style={{
      background: 'linear-gradient(to left, #0E0E11 0%, rgba(14,14,17,0.7) 70%, rgba(14,14,17,0) 100%)'
    }}></div>
  </>
);

const CarouselButton = ({ direction, onClick, disabled }) => (
  <button
    onClick={onClick}
    className={`absolute ${direction}-0 top-1/2 transform -translate-y-1/2 z-20 bg-white rounded-full p-2 shadow-lg ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
    disabled={disabled}
  >
    {direction === 'left' ? <ChevronLeft className="w-6 h-6 text-gray-800" /> : <ChevronRight className="w-6 h-6 text-gray-800" />}
  </button>
);

const HairStyleCarousel = () => {
  return (
    <div className="flex flex-col items-center justify-center bg-[#0E0E11] text-white py-16 px-8">
      <div className="max-w-6xl w-full ml-20">
        <div className="text-center mb-16">
          <h1 className="text-5xl font-bold text-white mb-2">
            다양한 헤어스타일 / 배경 제공
          </h1>
          <h2 className="text-xl text-[#999999]">
            다양한 헤어스타일 / 배경 중에서 원하는 디자인을 골라보세요
          </h2>
        </div>
        <ImageCarousel images={hairStyles} />
        <ImageCarousel images={backgroundStyles} />
        <div className="flex justify-center mt-8 space-x-4">
          <Link to="/generated-images" className="bg-white text-black px-6 py-3 rounded-full inline-flex items-center">
            생성된 이미지들 <ArrowRight className="ml-2 h-4 w-4" />
          </Link>
          <Link to="/demo" className="bg-white text-black px-6 py-3 rounded-full inline-flex items-center">
            헤어 모델 생성하기 <ArrowRight className="ml-2 h-4 w-4" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HairStyleCarousel;