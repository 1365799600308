import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const FirstSection = () => {

  // 이미지 URL들을 배열로 저장 (실제 URL로 교체 필요)
  const images = [
    'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/MAIN_PAGE_TOP/1.jpg',
    'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/MAIN_PAGE_TOP/2.jpg',
    'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/MAIN_PAGE_TOP/3.jpg',
    'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/MAIN_PAGE_TOP/4.jpg',
    'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/MAIN_PAGE_TOP/5.jpg',
    'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/MAIN_PAGE_TOP/6.jpg',
    'https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/MAIN_PAGE_TOP/7.jpg',
  ];

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#0E0E11] text-white p-8">
      <div className="pt-20"> {/* 이 줄을 추가합니다 */}
        <h1 className="text-6xl font-bold text-center mb-6 leading-[4rem]">
          원하는 헤어 디자인<br />
          <span className="bg-gradient-to-tr from-[#DA9EFF] to-[#0B41FF] text-transparent bg-clip-text">
            고퀄리티 헤어 모델 이미지
          </span>
          를<br />
          지금 바로 생성.
        </h1>
      </div>
    
      <div className="flex space-x-4 mb-8">
        <Link to="/generated-images" className="bg-white text-black px-6 py-3 rounded-full flex items-center">
          생성된 이미지들 <ArrowRight className="ml-2 h-4 w-4" />
        </Link>
        <Link to="/demo" className="bg-white text-black px-6 py-3 rounded-full flex items-center">
          헤어모델 생성하기 <ArrowRight className="ml-2 h-4 w-4" />
        </Link>
      </div>

      <div className="grid grid-cols-3 gap-2 mb-2">
        {images.slice(0, 3).map((img, index) => (
          <div key={index} className="w-[300px] h-[300px]">
            <img 
              src={img} 
              alt={`Generated hair model ${index + 1}`} 
              className="w-full h-full object-cover rounded-md" 
            />
          </div>
        ))}
      </div>
      <div className="grid grid-cols-4 gap-2">
        {images.slice(3).map((img, index) => (
          <div key={index + 3} className="w-[300px] h-[300px]">
            <img 
              src={img} 
              alt={`Generated hair model ${index + 4}`} 
              className="w-full h-full object-cover rounded-md" 
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FirstSection;