import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const StyleOption = ({ style, isSelected, onSelect, onViewLarge, isSelectable = true }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`relative border-4 ${isSelected ? 'border-white' : 'border-transparent'} rounded-lg overflow-hidden aspect-square`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ width: '200px', height: '200px' }}
    >
      <img src={style.image} alt={style.name} className="w-full h-full object-cover" />
      {isHovered && isSelectable && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-between p-2">
          <div className="text-right">
            <h3 className="text-white text-sm font-bold">{style.name}</h3>
            <p className="text-white text-xs">{style.description}</p>
          </div>
          <div className="flex flex-col items-end space-y-1">
            <button
              onClick={onViewLarge}
              className="bg-transparent text-white px-2 py-1 rounded-full text-xs border border-white"
            >
              크게 보기
            </button>
            <button
              onClick={onSelect}
              className="bg-white text-black px-2 py-1 rounded-full text-xs"
            >
              {isSelected ? '선택 해제' : '스타일 선택'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const Divider = () => (
  <div
    className="mx-auto my-4 border-t-[0.5px]"
    style={{ borderColor: '#494949', borderTopWidth: '0.1px' }}
  ></div>
);

const ImageModal = ({ image, onClose }) => {
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div className="max-w-3xl max-h-3xl" onClick={e => e.stopPropagation()}>
        <img
          src={image.image}
          alt={image.name}
          className="max-w-full max-h-full object-contain"
        />
      </div>
    </div>
  );
};

const StyleSelectionPage = () => {
  const [hairStyles, setHairStyles] = useState([]);
  const [backgroundStyles, setBackgroundStyles] = useState([]);
  const [selectedHair, setSelectedHair] = useState(null);
  const [selectedBackground, setSelectedBackground] = useState(null);
  const [error, setError] = useState('');
  const [modalImage, setModalImage] = useState(null);  // 여기에 modalImage와 setModalImage 선언
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStyleOptions = async () => {
      try {
        const response = await axios.get('https://api.basicfive.co.kr/api/v1/demo/style-options');
        setHairStyles(response.data.hairstyle_option_list);
        setBackgroundStyles(response.data.background_option_list);
      } catch (error) {
        console.error('Error fetching style options:', error);
        setError('스타일 옵션을 불러오는 데 실패했습니다.');
      }
    };

    fetchStyleOptions();
  }, []);

  const handleStyleSelect = (type, style) => {
    if (type === 'hair') {
      setSelectedHair(selectedHair && selectedHair.name === style.name ? null : style);
    } else if (type === 'background') {
      setSelectedBackground(selectedBackground && selectedBackground.name === style.name ? null : style);
    }
  };

  const handleViewLarge = (image) => {
    setModalImage(image);
  };

  const handleGenerate = () => {
    if (!selectedHair || !selectedBackground) {
      setError('헤어 / 배경 스타일을 선택해주세요');
      return;
    }
    setError('');
    navigate('/demo/generation', {
      state: {
        selectedHair,
        selectedBackground,
      }
    });
  };

  const imageStyles = [
    {
      id: 1,
      name: "자연스러운",
      description: "실제 사진 같은 느낌",
      image_url: "https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/IMAGE_STYLE_THUMBNAILS/pictorial.jpg"
    },
    {
      id: 2,
      name: "애니메이션",
      description: "만화 캐릭터 스타일",
      image_url: "https://dev-hairbe-salon.s3.ap-northeast-2.amazonaws.com/LANDING_PAGE/IMAGE_STYLE_THUMBNAILS/cellphone.jpg"
    },
  ];

  return (
    <div className="flex bg-[#0E0E11] text-white min-h-screen">
      {/* 네비게이션 바를 위한 공간 */}
      <div className="w-64 flex-shrink-0"></div>
      
      {/* 메인 컨텐츠 영역 */}
      <div className="flex-grow p-8 overflow-y-auto">
        <section className="mb-1">
          <h2 className="text-2xl font-semibold mb-1">헤어스타일을 선택해주세요</h2>
          <div className="flex flex-wrap gap-1">
            {hairStyles.map(style => (
              <StyleOption
                key={style.name}
                style={{...style, image: style.image_url}}
                isSelected={selectedHair && selectedHair.name === style.name}
                onSelect={() => handleStyleSelect('hair', style)}
                onViewLarge={() => handleViewLarge(style)}
              />
            ))}
          </div>
        </section>

        <Divider />

        <section className="mb-1">
          <h2 className="text-2xl font-semibold mb-1">배경스타일을 선택해주세요</h2>
          <div className="flex flex-wrap gap-1">
            {backgroundStyles.map(style => (
              <StyleOption
                key={style.name}
                style={{...style, image: style.image_url}}
                isSelected={selectedBackground && selectedBackground.name === style.name}
                onSelect={() => handleStyleSelect('background', style)}
                onViewLarge={() => handleViewLarge(style)}
              />
            ))}
          </div>
        </section>

        <Divider />

        <section className="mb-8">
          <h2 className="text-2xl font-semibold">이미지 스타일을 선택해주세요</h2>
          <p className="text-sm text-gray-400 mb-1">*해당 기능은 업데이트 예정입니다</p>
          <div className="flex flex-wrap gap-1">
            {imageStyles.map(style => (
              <StyleOption
                key={style.id}
                style={{...style, image: style.image_url}}
                isSelected={false}
                onSelect={() => {}}
                onViewLarge={() => handleViewLarge(style)}
                isSelectable={false}
              />
            ))}
          </div>
        </section>

        <div className="fixed bottom-8 left-72 flex flex-col items-start">
          {error && <p className="text-red-500 text-sm mb-2">{error}</p>}
          <button
            onClick={handleGenerate}
            className="bg-white text-black px-6 py-3 rounded-full text-lg font-bold"
          >
            생성하기
          </button>
        </div>
        {modalImage && (
          <ImageModal
            image={{...modalImage, image: modalImage.image_url}}
            onClose={() => setModalImage(null)}
          />
        )}
      </div>
    </div>
  );
};

export default StyleSelectionPage;